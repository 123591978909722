import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, BookOpen, Calculator, Globe, Beaker, GraduationCap } from 'lucide-react';
import assessmentsData from './assessments.json';

const teachers = [
  { name: 'Ms. P', fullName: 'Ms. Phillips', subject: 'Science', color: 'bg-blue-100 border-blue-300', icon: Beaker },
  { name: 'Ms. B', fullName: 'Ms. Biondolillo', subject: 'Language Arts', color: 'bg-yellow-100 border-yellow-300', icon: BookOpen },
  { name: 'Ms. V', fullName: 'Ms. Vaccaro', subject: 'Social Studies', color: 'bg-purple-100 border-purple-300', icon: Globe },
  { name: 'Ms. A', fullName: 'Ms. Adamo', subject: 'Math', color: 'bg-green-100 border-green-300', icon: Calculator }
];

const colorMap = {
  green: 'bg-green-400',
  yellow: 'bg-yellow-400',
  orange: 'bg-orange-400',
  red: 'bg-red-500'
};

const SchoolProgressTracker = () => {
  const [currentDate, setCurrentDate] = useState(new Date(2024, 8, 16)); // September 16, 2024
  const [assessments, setAssessments] = useState({});

  useEffect(() => {
    setAssessments(assessmentsData);
  }, []);

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric' });
  };

  const changeDay = (amount) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + amount);
    
    const minDate = new Date(2024, 8, 16); // September 16, 2024
    const maxDate = new Date(2024, 8, 20); // September 20, 2024

    if (newDate >= minDate && newDate <= maxDate) {
      setCurrentDate(newDate);
    }
  };

  const dateString = currentDate.toISOString().split('T')[0];

  const getWeekDates = () => {
    const dates = [];
    let currentDate = new Date(2024, 8, 16); // Monday, September 16, 2024
    for (let i = 0; i < 5; i++) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="bg-gray-200 p-6 rounded-lg shadow-md mb-6">
        <div className="flex justify-center items-center">
          <GraduationCap className="w-10 h-10 mr-3 text-blue-600" />
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800">School Progress Updates</h1>
        </div>
      </div>
      
      <div className="bg-white p-4 rounded-lg shadow overflow-x-auto mb-6">
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Subject</th>
              {getWeekDates().map(date => (
                <th key={date.toISOString()} className="px-4 py-2">{formatDate(date)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {teachers.map(teacher => (
              <tr key={teacher.name}>
                <td className="border px-4 py-2">{teacher.subject} ({teacher.name})</td>
                {getWeekDates().map(date => {
                  const dateStr = date.toISOString().split('T')[0];
                  const score = assessments[dateStr]?.[teacher.name]?.score;
                  return (
                    <td key={dateStr} className={`border px-4 py-2 ${score ? colorMap[score] : ''}`}></td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mb-6 bg-white p-4 rounded-lg shadow">
        <button 
          onClick={() => changeDay(-1)} 
          className="p-2 rounded-full hover:bg-gray-200 disabled:opacity-50"
          disabled={currentDate.getTime() === new Date(2024, 8, 16).getTime()}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <div className="text-lg md:text-xl font-semibold">
          {formatDate(currentDate)}
        </div>
        <button 
          onClick={() => changeDay(1)} 
          className="p-2 rounded-full hover:bg-gray-200 disabled:opacity-50"
          disabled={currentDate.getTime() === new Date(2024, 8, 20).getTime()}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          {teachers.map(({ name, fullName, subject, color, icon: Icon }) => (
            <div key={name} className={`p-4 rounded-lg ${color}`}>
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg md:text-xl font-medium">{name} - {subject}</h3>
                <Icon className="w-6 h-6" title={subject} />
              </div>
              <div className="w-full p-3 border rounded-md bg-white min-h-[40px]">
                {assessments[dateString]?.[name]?.comment || `No update from ${fullName} for this date.`}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SchoolProgressTracker;